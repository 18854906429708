<template>
  <div>
    <app-create-order ref="createOrderDialog"></app-create-order>
    <v-list>
      <v-list-item>
        <v-btn block text color="primary" outlined @click="addOrder()" class="text-none" v-if="!view">
          Добави услуга
        </v-btn>
      </v-list-item>
      <template v-for="(order_item,i) in order_items">
        <v-list-item :key="i">
          <v-list-item-content>
            <v-list-item-title>
              {{ order_item.Name }}
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-text-field outlined v-model="order_item.quantity" label="Брой" :readonly="view"></v-text-field>
          </v-list-item-action>
        </v-list-item>
      </template>
      <v-list-item v-if="order_items && order_items.length && !view">
        <v-btn block text color="green" outlined @click="saveOrder()" class="text-none">
          Създай поръчка
        </v-btn>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
export default {
  name: "OrderList",
  props: ['order_items', 'view'],
  components: {
    appCreateOrder: () => import('@/components/AddService/AddService')
  },
  methods: {
    addOrder() {
      this.$refs.createOrderDialog.open(this.order_items).then(data => {
        console.log(data)
        if (data) {

          this.order_items = data;
        }
      })
    },
    saveOrder() {
      let self = this;
      this.post(`admin/order/create`, {
        services: this.order_items
      }).then(() => {
        self.fetchOrders()
      })
    }
  },
  created() {
    console.log('created')
  }
}
</script>

<style scoped>

</style>